const bandit = {
  description: "A dirty thieving piece of shit.",
  encounters: [
    {
      name: "your mother",
      description:
        "You've been a naughty thieving shit and she knows it. She punches your teeth out your arse. Others may drink.",
    },
    {
      name: "a beaten travelling merchant",
      description:
        "Some old bastard you've robbed a few times. You beat him until he shits shitty blood and steal his booze. Everyone drinks.",
    },
    {
      name: "the sheriff of Knottednob",
      description:
        "He fails to recognise that you're a dodgy no good mongrel. You pick his pockets. Decide who drinks and who doesn't.",
    },
    {
      name: "another bandit",
      description:
        "This filthy and cunning prick wants a wager. Flip a coin for a drink. Heads can drink, tails gets fucked.",
    },
    {
      name: "a dead traveller",
      description:
        "Because you're scum you take a shit on them. Any one who needs to relieve themselves should do so now.",
    },
  ],
  exclamations: [
    "I just found it",
    "You don't know me",
    "You want a go cunt",
    "I swear it's not stolen",
    "Dick. Shit. Balls",
  ],
  inventory: [
    {
      name: "stolen item",
      description:
        "Unfortunately you're a shit thief and it's not worth anything. Everyone but you can have a drink.",
    },
    {
      name: "pair of underpants",
      description:
        "You've nicked knickers. Have a drink and steal a drink if you like.",
    },
    {
      name: "bandits club",
      description:
        "A great big horrible looking thing that can be used to bludgeon people unconscious or to death. Steal as many drinks as you like from others.",
    },
    {
      name: "stolen drink",
      description:
        "Spoils to be enjoyed with whom ever you choose. You and anyone else you like can have an extra drink.",
    },
    {
      name: "stash of grog",
      description:
        "Given you're a dirty scummy scum bag and you're always pilfering and flogging things, you have some extra booze. You and anyone else you like can have an extra drink.",
    },
  ],
  locations: ["gutter ", "alley", "backstreet", "sewer", "mire"],
};

export default bandit;
