import { Box, Button, Container, Title } from "garth";
import { useContext } from "react";
import { Link } from "react-router-dom";
import defaultState from "./state";
import StateContext from "./StateContext";
import ResponsiveGraphic from "./ResponsiveGraphic";
import { ReactComponent as Knight } from "./assets/knight.svg";
function Home() {
  const [, setState] = useContext(StateContext);
  const handleClick = () => {
    setState(defaultState);
  };
  return (
    <Container>
      <Box>
        <Title>Drinking the RPG</Title>
      </Box>
      <Box>
        <ResponsiveGraphic>
          <Knight />
        </ResponsiveGraphic>
      </Box>
      <Box>
        <Button element={Link} onClick={handleClick} to="/camp">
          Start
        </Button>
      </Box>
      <Box>
        <Button element={Link} to="/instructions">
          Instructions
        </Button>
      </Box>
      <Box>&copy; Garth</Box>
    </Container>
  );
}

export default Home;
