const rogue = {
  description: "A shady and dishonest character.",
  encounters: [
    {
      name: "a well lit hallway",
      description:
        "This is too much of a match for the rogue who has an inability to perform any actions outside of sneaking. Everyone else can drink.",
    },
    {
      name: "someone's exposed front",
      description: "Rogues only do it from behind. Everyone else take a drink.",
    },
    {
      name: "flatulence",
      description:
        "As you approach from behind a large gust blasts into your face. You have to drink covering your nose.",
    },
    {
      name: "a blind traveller",
      description: "You kill them and take their belongings. Enjoy a drink.",
    },
    {
      name: "a quiet moment",
      description:
        "It is the perfect opportunity to defecate or urinate. Any one who needs to relieve themselves should do so now.",
    },
  ],
  exclamations: [
    "Bend over",
    "From behind",
    "Look! Behind you",
    "Over there",
    "Fuck",
  ],
  inventory: [
    {
      name: "knife",
      description:
        "Lacking any loyalty or moral compass you are more than happy to take from others when the opportunity arises. Take the drinks from others if you'd like.",
    },
    {
      name: "distraction",
      description:
        "Describe in detail what this distracting thing is. Skip a drink if you please.",
    },
    {
      name: "distended anus",
      description:
        "What the fuck do you even use this for? Make up a rule for this turn.",
    },
    {
      name: "filthy lie",
      description:
        "Lie about having something good that means you can do what ever you like.",
    },
    {
      name: "poisonous insect",
      description:
        "Not lethal but something that is likely to get someone a little fucked up. Anyone you want has to take 2 extra drinks.",
    },
  ],
  locations: ["forest", "hill", "mountain", "courtyard", "house"],
};

export default rogue;
