import { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import defaultState from "./state";
import StateContext from "./StateContext";
import Camp from "./Camp";
import Encounter from "./Encounter";
import Instructions from "./Instructions";
import Inventory from "./Inventory";
import Idle from "./Idle";
import Start from "./Start";

const STORAGE_KEY = "DRINKING_THE_RPG";

function App() {
  const storage = localStorage.getItem(STORAGE_KEY);
  const [state, setState] = useState(
    storage ? JSON.parse(storage) : defaultState
  );
  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(state));
  }, [state]);
  return (
    <StateContext.Provider value={[state, setState]}>
      <BrowserRouter>
        <Switch>
          <Route component={Camp} exact path="/camp" />
          <Route component={Instructions} exact path="/instructions" />
          <Route component={Inventory} exact path="/inventory" />
          <Route component={Encounter} exact path="/encounter" />
          <Route component={Start} exact path="/" />
          <Route component={Idle} exact path="/idle" />
        </Switch>
      </BrowserRouter>
    </StateContext.Provider>
  );
}

export default App;
