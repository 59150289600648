import { Box, Button, Container, Paragraph, Title } from "garth";
import { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import CharacterContext from "./StateContext";
import getRandomArrayItem from "./getRandomArrayItem";

function Idle() {
  const states = [
    "Standing alert and ready for the next encounter.",
    "Waiting impatiently for something interesting to happen.",
    "Restlessly anticipating the next encounter.",
    "Quietly confident and eager for a fight.",
  ];
  const history = useHistory();
  const [state, setState] = useContext(CharacterContext);
  const { inventory, stamina } = state;
  const handleInventory = () => {
    setState({
      ...state,
      inventory: inventory - 1,
    });
    history.push("/inventory");
  };
  const handleEncounter = () => {
    setState({
      ...state,
      stamina: stamina - 1,
    });
    history.push("/encounter");
  };
  const displayStatus = () => {
    if (inventory <= 0 && stamina <= 0) {
      return "You have exhausted both yourself and all of your arsenal. Explore and drink at the whim of others.";
    }
    return getRandomArrayItem(states);
  };
  return (
    <Container>
      <Box>
        <Title>Idle</Title>
      </Box>
      <Box>
        <Paragraph>{displayStatus()}</Paragraph>
      </Box>
      <Box>
        <Button disabled={stamina <= 0} onClick={handleEncounter}>
          Explore a location ({stamina})
        </Button>
      </Box>
      <Box>
        <Button disabled={inventory <= 0} onClick={handleInventory}>
          Draw an item ({inventory})
        </Button>
      </Box>
      <Box>
        <Button element={Link} to="/camp">
          Return to camp
        </Button>
      </Box>
    </Container>
  );
}

export default Idle;
