import bandit from "./characters/bandit";
import knight from "./characters/knight";
import rogue from "./characters/rogue";
import troll from "./characters/troll";

const characters = {
  knight,
  rogue,
  bandit,
  troll,
};

export default characters;
