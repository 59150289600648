import { Box, Button, Container, Paragraph, Title } from "garth";
import { useContext } from "react";
import { Link } from "react-router-dom";
import StateContext from "./StateContext";
import ResponsiveGraphic from "./ResponsiveGraphic";
import characters from "./characters";
import getRandomArrayItem from "./getRandomArrayItem";

import { ReactComponent as Bandit } from "./assets/bandit.svg";
import { ReactComponent as Knight } from "./assets/knight.svg";
import { ReactComponent as Rogue } from "./assets/rogue.svg";
import { ReactComponent as Troll } from "./assets/troll.svg";

function Encounter() {
  const [{ character }] = useContext(StateContext);
  const { encounters, exclamations, locations } = characters[character];
  const encounter = getRandomArrayItem(encounters);
  const exclamation = getRandomArrayItem(exclamations);
  const location = getRandomArrayItem(locations);
  const image = {
    bandit: Bandit,
    knight: Knight,
    rogue: Rogue,
    troll: Troll,
  };
  const Image = image[character];
  return (
    <Container>
      <Box>
        <Title>Explore</Title>
      </Box>
      <Box>
        <ResponsiveGraphic>
          <Image />
        </ResponsiveGraphic>
      </Box>
      <Box>
        <Paragraph>
          You enter a {location} and encounter {encounter.name}. You exclaim —{" "}
          {exclamation}!
        </Paragraph>
      </Box>
      <Box>
        <Paragraph>{encounter.description}</Paragraph>
      </Box>
      <Box>
        <Paragraph>
          Surely someone is prepared for this situation? If you would like to
          use your inventory do so now.
        </Paragraph>
      </Box>
      <Box>
        <Button element={Link} to="/idle">
          Satisfy the encounter
        </Button>
      </Box>
    </Container>
  );
}

export default Encounter;
