import { Box, Button, Container, Paragraph, Title } from "garth";
import { Link } from "react-router-dom";

function Instructions() {
  return (
    <Container>
      <Box>
        <Title>Instructions</Title>
      </Box>
      <Box>
        <Paragraph>Ensure you have an ample supply of drinks.</Paragraph>
      </Box>
      <Box>
        <Paragraph>Select a character to play as.</Paragraph>
      </Box>
      <Box>
        <Paragraph>Take it in turns to perform encounters.</Paragraph>
      </Box>
      <Box>
        <Paragraph>
          During an encounter you can optionally use an item from your
          inventory.
        </Paragraph>
      </Box>
      <Box>
        <Button element={Link} to="/camp">
          Start
        </Button>
      </Box>
    </Container>
  );
}

export default Instructions;
