import { Box, Button, Container, Paragraph, Title } from "garth";
import { useContext } from "react";
import { Link } from "react-router-dom";
import StateContext from "./StateContext";
import ResponsiveGraphic from "./ResponsiveGraphic";
import characters from "./characters";
import { ReactComponent as Inventory } from "./assets/inventory.svg";
import getRandomArrayItem from "./getRandomArrayItem";
function Equipment() {
  const [{ character }] = useContext(StateContext);
  const { inventory } = characters[character];
  var item = getRandomArrayItem(inventory);
  return (
    <Container>
      <Box>
        <Title>Draw item</Title>
      </Box>
      <Box>
        <ResponsiveGraphic>
          <Inventory />
        </ResponsiveGraphic>{" "}
      </Box>
      <Box>
        <Paragraph>
          You reach to your inventory and produce a {item.name}.{" "}
          {item.description}
        </Paragraph>
      </Box>
      <Box>
        <Button element={Link} to="/idle">
          Use item
        </Button>
      </Box>
    </Container>
  );
}

export default Equipment;
