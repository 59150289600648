const troll = {
  description: "Not a fan of drinking.",
  encounters: [
    {
      name: "a halfling",
      description:
        "This greedy little tobacco smoking alcoholic is a proper arse hole. He's probably wanting to wax on until the sun comes up. Fuck this guy. Any one can have a drink if they please.",
    },
    {
      name: "a large stubborn booger",
      description:
        "Your nostrils are rammed with dried mucus and it's become sentient and obnoxious. If you need to pick your nose do it now.",
    },
    {
      name: "a billy goat",
      description:
        "This gruff cunt is here to ruin your day. Unless an item allows it no one drinks.",
    },
    {
      name: "a small billy goat",
      description:
        "This little lying cunt promises some other cunt is coming that will brighten your day. You're a sucker and believe him, he goes on his way. If you feel the need have a drink.",
    },
    {
      name: "the head that's grown out of your shoulder",
      description:
        "Almost as bad as you are, you fucker. After punching or not punching yourself in the head and the dick (who knows), you've fucked around and missed the turn.",
    },
  ],
  exclamations: [
    "I like bridges",
    "I smell shit",
    "Goats",
    "Did some one hear a goat? I thought I did",
    "It's not hair, it's just fungus growing from my skull",
  ],
  inventory: [
    {
      name: "half a goblin",
      description:
        "Not something pleasant nor something anyone would want to be caught with. Describe which half it is.",
    },
    {
      name: "goat skin",
      description:
        "Better than a goat containing goat contents. You mumble and try and draw attention to something else.",
    },
    {
      name: "mangled minstrel",
      description:
        "How did that get there. Must have picked it up at the last village. Any who, moving on. Anyone can take a drink. No one is allowed to make eye contact.",
    },
    {
      name: "halfling",
      description:
        "These pricks are like rats, they get fucking everywhere. Check all your pockets and any other nooks and crannies for small people. Everyone else can drink.",
    },
    {
      name: "innocuous fluid",
      description: "Nothing particularly useful or offensive. Nothing happens.",
    },
  ],
  locations: ["river", "ditch", "bridge", "moat", "swamp"],
};

export default troll;
