const knight = {
  description: "A fancier of the equine.",
  encounters: [
    {
      name: "a wild horse",
      description:
        "Never have you witnessed such beauty nor felt such discomfort wearing steel trousers. Others may take a drink, you're going to need a moment.",
    },
    {
      name: "your ex horse",
      description:
        "This jealous and filthy stead has a bad bit of stink in it's eye. Someone needs to console it with a drink for the party to escape. Everyone else has to close their eyes.",
    },
    {
      name: "peasants",
      description:
        "Beat the diseased, starved, overworked, and mistreated peasants. Afterwards you enjoy a drink.",
    },
    {
      name: "the vision of knickers wedged badly in your nether region",
      description:
        "Despite initial discomfort you begin to enjoy the idea of pressure mangling your downstairs. Everyone else needs a drink.",
    },
    {
      name: "a pimply anus monster",
      description:
        "You have the power to stimulate the excrement out of people and this might be a good time to do so. Any one who needs to relieve themselves should do so now.",
    },
  ],
  exclamations: [
    "How in balls shall we deal with this great gathering of anus",
    "Mother",
    "Is that you my sweet damsel",
    "For my king",
    "Fucking peasants",
  ],
  inventory: [
    {
      name: "long broad sword",
      description:
        "Great for intimidating peasants. Drink the drinks of others if you please.",
    },
    {
      name: "horse brush",
      description:
        "You drift off into the thoughts of a tender moment once shared. Any one else can drink whilst you are distracted.",
    },
    {
      name: "sack of peasant dicks",
      description:
        "Known as a soggy sack wacker. Optionally skip any drinks required.",
    },
    {
      name: "sword hilt",
      description: "Wooden with a large knob at the end. Does nothing.",
    },
    {
      name: "maiden’s privates",
      description:
        "These are locked down with enough iron to prevent any sort of penetration. Optionally skip any drinks required.",
    },
  ],
  locations: ["castle", "moat", "stable", "field", "horse"],
};

export default knight;
