import { Box, Button, Checkable, Container, Paragraph, Title } from "garth";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import characters from "./characters";
import StateContext from "./StateContext";
import ResponsiveGraphic from "./ResponsiveGraphic";

import { ReactComponent as Bandit } from "./assets/bandit.svg";
import { ReactComponent as Knight } from "./assets/knight.svg";
import { ReactComponent as Rogue } from "./assets/rogue.svg";
import { ReactComponent as Troll } from "./assets/troll.svg";

function Camp() {
  const history = useHistory();
  const [state, setState] = useContext(StateContext);
  const { handleSubmit, register, watch } = useForm();
  const onSubmit = ({ character }) => {
    setState({ ...state, character });
    history.push("/idle");
  };
  const image = {
    bandit: Bandit,
    knight: Knight,
    rogue: Rogue,
    troll: Troll,
  };
  const Image = image[watch("character", "bandit")];
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container>
        <Box>
          <Title>Camp</Title>
        </Box>
        <Box>
          <Paragraph>Select a character to drink as.</Paragraph>
        </Box>
        <Box>
          <Checkable
            id="bandit"
            inputRef={register({ required: true })}
            name="character"
            type="radio"
            value="bandit"
            defaultChecked
          >
            Bandit
          </Checkable>
        </Box>
        <Box>
          <Checkable
            id="knight"
            inputRef={register({ required: true })}
            name="character"
            type="radio"
            value="knight"
          >
            Knight
          </Checkable>
        </Box>
        <Box>
          <Checkable
            id="rogue"
            inputRef={register({ required: true })}
            name="character"
            type="radio"
            value="rogue"
          >
            Rogue
          </Checkable>
        </Box>
        <Box>
          <Checkable
            id="troll"
            inputRef={register({ required: true })}
            name="character"
            type="radio"
            value="troll"
          >
            Troll
          </Checkable>
        </Box>
        <Box>
          <Title>The {watch("character", "bandit")}</Title>
          <ResponsiveGraphic>
            <Image />
          </ResponsiveGraphic>
          <Paragraph>
            {characters[watch("character", "bandit")].description}
          </Paragraph>
        </Box>
        <Box>
          <Button type="submit">Begin quest</Button>
        </Box>
        <Box>
          <Button element={Link} to="/">
            Exit
          </Button>
        </Box>
      </Container>
    </form>
  );
}

export default Camp;
